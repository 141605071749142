import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from './utils/bodyScrollLock';
export default class default_1 extends Controller {
    static values = {
        mobileMenuUrl: String,
        destinationsMenuUrl: String,
        categoriesMenuUrl: String,
        aboutMenuUrl: String,
    };
    static targets = [
        "destinationsArrow",
        "destinationsButton",
        "destinationsMenu",
        "categoriesArrow",
        "categoriesButton",
        "categoriesMenu",
        "aboutArrow",
        "aboutButton",
        "aboutMenu",
        "mobileMenu",
        "claims",
        "logoClaim"
    ];
    isDestinationsOpen = false;
    isCategoriesOpen = false;
    isAboutOpen = false;
    isMobileMenuOpen = false;
    isMobileMenuLoaded = false;
    isDestinationsMenuLoaded = false;
    isCategoriesMenuLoaded = false;
    isAboutMenuLoaded = false;
    connect() {
        // @ts-ignore
        useClickOutside(this);
        if (this.hasClaimsTarget) {
            window.addEventListener('scroll', () => {
                const height = this.claimsTarget.offsetHeight || 0;
                if (height) {
                    this.element.style.top = window.scrollY > height ? `-${height}px` : '0';
                    if (this.hasLogoClaimTarget) {
                        this.logoClaimTarget.style.display = window.scrollY > height ? 'block' : 'none';
                    }
                }
                else if (this.hasLogoClaimTarget) {
                    this.logoClaimTarget.style.display = 'block';
                }
            });
        }
        else if (this.hasLogoClaimTarget) {
            this.logoClaimTarget.style.display = 'block';
        }
        // Event listener for the popstate event (triggered by back/forward buttons)
        window.addEventListener('popstate', (event) => {
            // Check if the modal was open
            if (!event.state || !event.state.mobileMenuOpen) {
                this.hideMobileMenu();
            }
        });
    }
    disconnect() {
        clearAllBodyScrollLocks();
    }
    // noinspection JSUnusedGlobalSymbols
    clickOutside(event) {
        if (this.isDestinationsOpen) {
            event.preventDefault();
            this.hideDestinations();
        }
        if (this.isCategoriesOpen) {
            event.preventDefault();
            this.hideCategories();
        }
        if (this.isAboutOpen) {
            event.preventDefault();
            this.hideAbout();
        }
        clearAllBodyScrollLocks();
    }
    // noinspection JSUnusedGlobalSymbols
    hideAll() {
        if (this.isDestinationsOpen) {
            this.hideDestinations();
        }
        if (this.isCategoriesOpen) {
            this.hideCategories();
        }
        if (this.isAboutOpen) {
            this.hideAbout();
        }
        if (this.isMobileMenuOpen) {
            this.hideMobileMenu();
        }
        clearAllBodyScrollLocks();
    }
    // noinspection JSUnusedGlobalSymbols
    toggleDestinations() {
        if (this.isCategoriesOpen) {
            this.hideCategories(0);
        }
        if (this.isAboutOpen) {
            this.hideAbout(0);
        }
        if (this.isDestinationsOpen) {
            this.hideDestinations();
        }
        else {
            this.showDestinations();
        }
        this.setAboutArrow();
        this.setCategoriesArrow();
    }
    // noinspection JSUnusedGlobalSymbols
    toggleCategories() {
        if (this.isDestinationsOpen) {
            this.hideDestinations(0);
        }
        if (this.isAboutOpen) {
            this.hideAbout(0);
        }
        if (this.isCategoriesOpen) {
            this.hideCategories();
            // this.destinationsButtonTarget.style.borderBottomColor = '';
            // this.destinationsButtonTarget.classList.remove('text-gray-500');
            // this.destinationsButtonTarget.classList.add('text-gray-900');
            // this.aboutButtonTarget.style.borderBottomColor = '';
            // this.aboutButtonTarget.classList.remove('text-gray-500');
            // this.aboutButtonTarget.classList.add('text-gray-900');
        }
        else {
            this.showCategories();
            // this.destinationsButtonTarget.style.borderBottomColor = 'transparent';
            // this.destinationsButtonTarget.classList.remove('text-gray-900');
            // this.destinationsButtonTarget.classList.add('text-gray-500');
            // this.aboutButtonTarget.style.borderBottomColor = 'transparent';
            // this.aboutButtonTarget.classList.remove('text-gray-900');
            // this.aboutButtonTarget.classList.add('text-gray-500');
        }
        this.setAboutArrow();
        this.setDestinationsArrow();
    }
    // noinspection JSUnusedGlobalSymbols
    toggleAbout() {
        if (this.isDestinationsOpen) {
            this.hideDestinations(0);
        }
        if (this.isCategoriesOpen) {
            this.hideCategories(0);
        }
        if (this.isAboutOpen) {
            this.hideAbout();
        }
        else {
            this.showAbout();
        }
        this.setDestinationsArrow();
        this.setCategoriesArrow();
    }
    setDestinationsArrow() {
        if (this.hasDestinationsArrowTarget) {
            if (this.isDestinationsOpen) {
                this.destinationsArrowTarget.classList.add("scale-y-[-1]");
            }
            else {
                this.destinationsArrowTarget.classList.remove("scale-y-[-1]");
            }
        }
    }
    setCategoriesArrow() {
        if (this.hasCategoriesArrowTarget) {
            if (this.isCategoriesOpen) {
                this.categoriesArrowTarget.classList.add("scale-y-[-1]");
            }
            else {
                this.categoriesArrowTarget.classList.remove("scale-y-[-1]");
            }
        }
    }
    setAboutArrow() {
        if (this.hasAboutArrowTarget) {
            if (this.isAboutOpen) {
                this.aboutArrowTarget.classList.add("scale-y-[-1]");
            }
            else {
                this.aboutArrowTarget.classList.remove("scale-y-[-1]");
            }
        }
    }
    async showDestinations(y = -5) {
        if (this.hasDestinationsMenuTarget) {
            this.isDestinationsOpen = true;
            await this.show(this.destinationsMenuTarget, this.destinationsArrowTarget, y);
            disableBodyScroll(this.destinationsMenuTarget, {
                reserveScrollBarGap: true,
            });
            if (!this.isDestinationsMenuLoaded) {
                this.isDestinationsMenuLoaded = true;
                const response = await fetch(this.destinationsMenuUrlValue);
                this.destinationsMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Reiseziele', window.location.href]);
            }
        }
    }
    async hideDestinations(y = -5) {
        if (this.hasDestinationsMenuTarget) {
            this.isDestinationsOpen = false;
            this.hide(this.destinationsMenuTarget, this.destinationsArrowTarget, y);
            enableBodyScroll(this.destinationsMenuTarget);
        }
    }
    async showCategories(y = -5) {
        if (this.hasCategoriesMenuTarget) {
            this.isCategoriesOpen = true;
            await this.show(this.categoriesMenuTarget, this.categoriesArrowTarget, y);
            disableBodyScroll(this.categoriesMenuTarget, {
                reserveScrollBarGap: true,
            });
            if (!this.isCategoriesMenuLoaded) {
                this.isCategoriesMenuLoaded = true;
                const response = await fetch(this.categoriesMenuUrlValue);
                this.categoriesMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Reisethemen', window.location.href]);
            }
        }
    }
    async hideCategories(y = -5) {
        if (this.hasCategoriesMenuTarget) {
            this.isCategoriesOpen = false;
            await this.hide(this.categoriesMenuTarget, this.categoriesArrowTarget, y);
            enableBodyScroll(this.categoriesMenuTarget);
        }
    }
    async showAbout(y = -5) {
        if (this.hasAboutMenuTarget) {
            this.isAboutOpen = true;
            await this.show(this.aboutMenuTarget, this.aboutArrowTarget, y);
            disableBodyScroll(this.aboutMenuTarget, {
                reserveScrollBarGap: true,
            });
            if (!this.isAboutMenuLoaded) {
                this.isAboutMenuLoaded = true;
                const response = await fetch(this.aboutMenuUrlValue);
                this.aboutMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Über Umfulana', window.location.href]);
            }
        }
    }
    async hideAbout(y = -5) {
        if (this.hasAboutMenuTarget) {
            this.isAboutOpen = false;
            await this.hide(this.aboutMenuTarget, this.aboutArrowTarget, y);
            enableBodyScroll(this.aboutMenuTarget);
        }
    }
    // noinspection JSUnusedGlobalSymbols
    async showMobileMenu() {
        if (this.hasMobileMenuTarget && !this.isMobileMenuOpen) {
            this.isMobileMenuOpen = true;
            disableBodyScroll(this.mobileMenuTarget, {
                reserveScrollBarGap: true,
            });
            const GSAP = await import("gsap");
            GSAP.default.timeline()
                .set(this.mobileMenuTarget, {
                visibility: "visible",
                display: "inherit",
                opacity: 0
            })
                .to(this.mobileMenuTarget, {
                duration: 0.4,
                ease: GSAP.Power2.easeOut,
                opacity: 1
            });
            const tourMapButton = document.querySelector('[data-tour-target="mobileMapButton"]');
            if (tourMapButton) {
                tourMapButton.classList.add('hidden');
            }
            if (!this.isMobileMenuLoaded) {
                this.isMobileMenuLoaded = true;
                const response = await fetch(this.mobileMenuUrlValue);
                this.mobileMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Menü (Mobil)', window.location.href]);
            }
            window.history.pushState({ mobileMenuOpen: true }, '', '');
        }
    }
    async hideMobileMenu() {
        if (this.hasMobileMenuTarget && this.isMobileMenuOpen) {
            this.isMobileMenuOpen = false;
            enableBodyScroll(this.mobileMenuTarget);
            const GSAP = await import("gsap");
            GSAP.default.timeline()
                .to(this.mobileMenuTarget, {
                duration: 0.15,
                ease: GSAP.Power2.easeIn,
                opacity: 0
            })
                .set(this.mobileMenuTarget, {
                visibility: "hidden",
                display: "none"
            });
            const tourMapButton = document.querySelector('[data-tour-target="mobileMapButton"]');
            if (tourMapButton) {
                tourMapButton.classList.remove('hidden');
            }
            if (window.history.state && window.history.state.mobileMenuOpen) {
                // Triggering history.back() keeps the browser history consistent
                window.history.back();
            }
        }
    }
    // Entering: "transition ease-out duration-200"
    // From: "opacity-0 -translate-y-1"
    // To: "opacity-100 translate-y-0"
    async show(target, arrow, y = -5) {
        const GSAP = await import("gsap");
        GSAP.default.timeline()
            .set(arrow, {
            scaleY: -1
        })
            .set(target, {
            visibility: "visible",
            opacity: 0,
            y
        })
            .to(target, {
            duration: 0.2,
            ease: GSAP.Power2.easeOut,
            opacity: 1,
            y: 0
        });
    }
    // Leaving: "transition ease-in duration-150"
    // From: "opacity-100 translate-y-0"
    // To: "opacity-0 -translate-y-1"
    async hide(target, arrow, y = -5) {
        const GSAP = await import("gsap");
        GSAP.default.timeline()
            .set(arrow, {
            scaleY: 1
        })
            .to(target, {
            duration: 0.15,
            ease: GSAP.Power2.easeIn,
            opacity: 0,
            y
        })
            .set(target, {
            visibility: "hidden"
        });
    }
}
