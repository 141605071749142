import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";
export default class extends Controller {
    connect() {
        useResize(this);
        this.setDocumentHeight = this.setDocumentHeight.bind(this);
        this.setNavigationHeight = this.setNavigationHeight.bind(this);
        window.addEventListener('resize', this.setDocumentHeight);
        this.setDocumentHeight();
        // throttle scroll event
        let scrollThrottleTimer = false;
        let resizeThrottleTimer = false;
        let throttleDelay = 100;
        window.addEventListener('scroll', () => {
            if (!scrollThrottleTimer) {
                scrollThrottleTimer = setTimeout(() => {
                    scrollThrottleTimer = false;
                    this.setNavigationHeight();
                }, throttleDelay);
            }
        });
        // throttle resize event
        window.addEventListener('resize', () => {
            if (!resizeThrottleTimer) {
                resizeThrottleTimer = setTimeout(() => {
                    resizeThrottleTimer = false;
                    this.setNavigationHeight();
                }, throttleDelay);
            }
        });
        setTimeout(() => {
            this.setNavigationHeight();
        }, throttleDelay);
    }
    setDocumentHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
        // Hack for Safari (iOS)
        if (this.isSafariOniOS()) {
            this.findElements('--doc-height').forEach((element) => {
                element.style.opacity = '1';
            });
        }
    }
    setNavigationHeight() {
        const doc = document.documentElement;
        const nav = document.getElementById('umla-nav');
        const navClaims = document.getElementById('umla-nav-claims');
        const navMain = document.getElementById('umla-nav-main');
        let navHeight = 0;
        // scroll position from top
        if (window.scrollY > (navClaims?.getBoundingClientRect().height || 0)) {
            navHeight = navMain?.getBoundingClientRect().height || 0;
        }
        else {
            navHeight = nav?.getBoundingClientRect().height || 0;
        }
        doc.style.setProperty('--nav-height', `${navHeight}px`);
        // Hack for Safari (iOS)
        if (this.isSafariOniOS()) {
            this.findElements('--nav-height').forEach((element) => {
                element.style.opacity = '1';
            });
        }
    }
    // noinspection JSUnusedGlobalSymbols
    resize() {
        this.setDocumentHeight();
    }
    findElements(classNameToMatch) {
        const elements = Array.from(document.querySelectorAll('*'));
        return elements.filter((element) => {
            const classList = element.classList;
            return Array.from(classList).some(className => new RegExp(`\\b${classNameToMatch}\\b`).test(className));
        });
    }
    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    isSafariOniOS() {
        return !!(this.isSafari() && navigator.maxTouchPoints && /iPad|iPhone|Macintosh/i.test(navigator.userAgent));
    }
}
